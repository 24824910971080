<template>
  <div class="scheme">
    <!-- 解决方案 -->
    <div class="scene">
      <div class="WebMain">
        <p class="DivTitle">Successful case</p>
        <div class="DivContent">
          <template>
            <el-carousel :interval="10000" arrow="always" indicator-position="outside" @change="changeCarousel">
              <el-carousel-item v-for="item in paging.total" :key="item.id">
                <div class="container">
                  <div :class="$store.state.isPhone ? 'item phone': 'item'"  v-for="i in sceneList" :key="i.id">
                    <div :class="i.thumbnail ? 'top':'top none'">
                      <div class="bottomStyle"></div>
                      <p class="text p26">{{ i.title }}</p>
                      <p class="content p17">{{ i.summary }}</p>

                    </div>
                    <img v-if="i.thumbnail" :src="$store.state.requestAPI + i.thumbnail" alt="">
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </template>
        </div>
      </div>
    </div>
    <div class="sceneCase">
      <div class="item " v-for="item in sceneCaseList" :key="item.id">
        <img v-if="!$store.state.isPhone" class="wow bounceInU" :src="require(`../../assets/img/scheme/${item.imgPath}`)"
          alt="">
        <img v-else class="wow bounceInU" :src="require(`../../assets/img/scheme/${item.phoneImgPath}`)" alt="">
        <div class="WebMain">
          <div :class="$store.state.isPhone ? 'phone top':'top'"></div>
          <div :class="$store.state.isPhone ? 'phone bottom':'bottom'"></div>
          <div :class="$store.state.isPhone ? 'phone content wow1 slideInLeft' : 'content wow1 slideInLeft'">
            <div class="left">
              <p class="title p34">{{ item.title }}</p>
              <span class="style" v-if="!$store.state.isPhone"></span>
            </div>
            <p class="summary p20">{{ item.summary }}</p>
          </div>

        </div>

      </div>
    </div>
    <div class="flowPath">
      <div class="WebMain">
        <p class="DivTitle">One-stop software development</p>
        <div class="DivContent">
          <div class="item" v-for="item in flowPathList" :key="item.id">
            <div class="left">
              <img :src="require(`../../assets/img/scheme/${item.left.imgPath}`)" alt="">
              <div class="content">
                <p class="p20">{{ item.left.title }}</p>
                <p class="p17">{{ item.left.summary }}</p>
              </div>
            </div>
            <div class="right">
              <img :src="require(`../../assets/img/scheme/${item.right.imgPath}`)" alt="">
              <div class="content">
                <p class="p20">{{ item.right.title }}</p>
                <p class="p17">{{ item.right.summary }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wow.js'
import { articleListAPI } from '@/api/article/EnglishArticle'
export default {
  data() {
    return {
      sceneList: [],
      sceneCaseList: [
        {
          id: 1, title: 'APP/Mini Programs',
          summary: 'We provide native development for IOS/ANDROID, mobile program development using HTML5, and development of WeChat Mini Programs.。',
          imgPath: 'bg (1).jpg',
          phoneImgPath: 'bg1 (1).jpg'
        },
        {
          id: 2, title: 'Website Construction',
          summary: 'IOS/We offer various templates for rapid website construction, customizable website layout and style, SEO optimization support, and powerful backend management features.',
          imgPath: 'bg (2).jpg',
          phoneImgPath: 'bg1 (2).jpg'
        },
        {
          id: 3, title: 'Enterprise Management',
          summary: 'We provide customization for internal management systems, including CRM, OA, ERP, and other solutions that cover complete business processes. Our system enables office automation, business intelligence, and industrial intelligence.',
          imgPath: 'bg (3).jpg',
          phoneImgPath: 'bg1 (3).jpg'
        },
        {
          id: 4, title: 'Data Analysis System',
          summary: 'Our system is based on big data and provides multi-dimensional visualization analysis, collects data through multiple points, and provides end-to-end data access, breaking data silos. We provide support for decision-making and analysis.',
          imgPath: 'bg (4).jpg',
          phoneImgPath: 'bg1 (4).jpg'
        },
      ],
      flowPathList: [
        { id: 1, left: { title: 'Requirements analysis', summary: 'Project managers analyze requirements and devise software development plans.', imgPath: 'icon (1).png' }, right: { title: 'Software architecture', summary: 'Software architects design the software architecture.', imgPath: 'icon (2).png' } },

        { id: 2, left: { title: 'Interface design', summary: 'Designers provide customized interface and visual design.', imgPath: 'icon (3).png' }, right: { title: 'Program development', summary: 'Software engineers code the software according to strict coding standards.', imgPath: 'icon (4).png' } },

        { id: 3, left: { title: 'Quality management', summary: 'Follow GB/T19001/ISO9001 software quality management standards.', imgPath: 'icon (5).png' }, right: { title: 'Testing and acceptance', summary: 'Conduct unit testing, integration testing, system testing, stress testing, and acceptance testing.', imgPath: 'icon (6).png' } },

        { id: 4, left: { title: 'System delivery', summary: 'After the system is accepted and delivered, comprehensive system training is provided.', imgPath: 'icon (7).png' }, right: { title: 'Operation and maintenance', summary: 'Provide long-term operation and maintenance and security prevention services to ensure system usage.', imgPath: 'icon (8).png' } },
      ],
      paging: {
        pageNum: 1,
        pageSize: 3,
        total: 0
      }
    }
  },
  methods: {
    // 切换幻灯片
    changeCarousel() {
      if (document.body.clientWidth > 1200) {
        this.paging.pageSize = 3
      } else if (document.body.clientWidth < 900) {
        this.paging.pageSize = 1
      } else {
        this.paging.pageSize = 2
      }
      if (this.paging.pageNum < this.paging.total) {
        this.paging.pageNum = this.paging.pageNum + 1;
        this.getSceneList();
      } else {
        this.paging.pageNum = 1;
        this.getSceneList();
      }
    },
    //获取案例列表
    async getSceneList() {
      let info = {
        pageNum: this.paging.pageNum,
        pageSize: this.paging.pageSize,
        categoryId: 9,
        status: 'normal'
      }
      let info2 = {
        pageNum: 1,
        pageSize: 2,
        categoryId: 9,
        status: 'normal'
      }
      const res2 = await articleListAPI(JSON.stringify(info2));
      console.log(res2);
      let firstList = []
      if (res2.code === 200) {
        firstList = res2.data.list
      }
      const res = await articleListAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.sceneList = res.data.list;
        console.log(this.sceneList)
        if (this.paging.pageSize === 3 && this.sceneList.length !== 3) {
          if (this.sceneList.length = 1 ) {
            this.sceneList = [...this.sceneList, ...firstList ]
          } else if(this.sceneList.length = 2) {
            this.sceneList = [...this.sceneList, firstList[0] ]
          }
        } else if(this.paging.pageSize === 2 && this.sceneList.length !== 2) {
          if (this.sceneList.length = 1 ) {
            this.sceneList = [...this.sceneList, firstList[0] ]
          }
        }

        this.paging.total = Math.ceil(((res.data.total) / this.paging.pageSize));
      }
    }
  },
  created() {
    if (document.body.clientWidth > 1200) {
        this.paging.pageSize = 3
      } else if (document.body.clientWidth < 900) {
        this.paging.pageSize = 1
      } else {
        this.paging.pageSize = 2
      }
    if (this.$store.state.isPhone) {
      this.paging.pageSize = 1
    }
    // console.log(this.$store.state.isPhone)
    this.getSceneList();
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      offset: 150,
      mobile: true,
      live: true
    });
    wow.init();
    var wow1 = new WOW({
      boxClass: 'wow1',
      offset: 50,
      mobile: true,
      live: true
    });
    wow1.init();
  }
}
</script>

<style lang="scss" scoped>
@keyframes sceneCase {
  from {
    background: url('https://test2.zhihanyixing.com/api/image/1305c8c2377c4fbd8efc99728d60bf8c1305c8c2377c4fbd8efc99728d60bf8c.png') no-repeat;
  }

  to {}
}

.scheme {
  .scene {
    padding-bottom: 60px;

    .WebMain {
      .DivContent {
        ::v-deep .el-carousel__button {
          height: 12px;
          width: 12px;
          border-radius: 50%;
        }

        ::v-deep .el-carousel__indicator.is-active button {
          background-color: var(--custom-color);
        }

        ::v-deep .el-carousel__container {
          height: 400px !important;
          z-index: 0 !important;

          .el-carousel__arrow {
            top: 72% !important;
            background-color: white;
            color: #565656;
            font-size: 26px;
          }


        }



        .container {
          display: flex;
          justify-content: space-between;

          .item {
            position: relative;
            width: 383px;
            height: 395px;
            background: #F5F5F5;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.33);

            &:hover {
              .top {
                // z-index: 2;
                height: 100%;
                transition: all .8s;
                .text {
                  color: var(--custom-color);
                  transition: all .5s;
                }

                .bottomStyle {
                  width: 100%;
                  transition: all .8s;

                }
              }
              img{
                // opacity: 0.3;
                // z-index: -1;
                // height: 0;
                bottom: -220px;
                transition: all .8s;
              }
            }

            .top {
              padding: 30px 35px;
              padding-bottom: 40px;
              height: 99px;
              overflow: hidden;

              .bottomStyle {
                position: absolute;
                top: 0;
                left: 0;
                width: 0px;
                height: 7px;
                background: var(--custom-color);
              }

              .text {
                text-align: center;
                margin-bottom: 18px;
                color: #222222;
                line-height: 35px;
              }

              .content {
                color: #666666;
                line-height: 27px;
              }
            }
            .top.none{
              height: 100%;
            }

            img {
              height: 215px;
              width: 100%;
              position: absolute;
              bottom: 0;
            }
          }
          .item.phone{
            background: url('../../assets/img/scheme/caseBg.png');
            background-size: cover;
            background-repeat: no-repeat;
            .top{
              height: 100%;
            }
            img{
              display: none;

            }
          }
        }


      }
    }
  }

  .sceneCase {
    .item {
      width: 100%;
      height: 600px;
      background: #2C3135;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      p {
        color: white;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      .WebMain {
        // padding: 100px 50px;
        // padding: ;
        position: relative;
        box-sizing: border-box;

        // background: #666666;
        .content {
          padding: 133px 94px;

          // height: 300px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;

          .left {
            display: flex;
            align-items: center;
            margin-right: 20px;
          }

          .title {
            font-weight: 600;
            // display: inline-block;
          }

          .style {
            display: inline-block;
            width: 30px;
            height: 6px;
            background: var(--custom-color);
            margin-left: 10px;
          }


          .summary {
            width: 504px;
            line-height: 33px;
          }
        }

        .phone.content {
          padding: 130px 32px;
        }


        .phone.top,
        .phone.bottom {
          &:before,
          &:after {
            width: 40px;
            height: 40px;
          }
        }
        .top,
        .bottom {
          position: absolute;
          width: 100%;
          height: 70px;
          box-sizing: border-box;

          &:before,
          &:after {
            content: '';
            display: inline-block;

            background: url('https://test2.zhihanyixing.com/api/image/b45d7965532b4d7fa305dd0e51f5566cb45d7965532b4d7fa305dd0e51f5566c.png') no-repeat;
            // background: url('https://test2.zhihanyixing.com/api/image/1305c8c2377c4fbd8efc99728d60bf8c1305c8c2377c4fbd8efc99728d60bf8c.png') no-repeat;
            background-size: contain;
            width: 70px;
            height: 70px;
            position: absolute;

          }

          &:before {
            left: 38px
          }

          &:after {
            right: 38px
          }
        }

        .bottom {
          bottom: 0px;

          &:after {
            transform: rotate(180deg);
          }

          &:before {
            transform: rotate(270deg);
          }
        }

        .top {
          top: 0px;

          &:after {
            transform: rotate(90deg);
          }
        }

      }
    }
  }

  .flowPath {
    // background-color: whitesmoke;
    background: url('../../assets/img/scheme/bg.png');
    background-size: cover;
    padding-bottom: 60px;

    .WebMain {
      .DivContent {
        .item {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;


          .left,
          .right {
            display: flex;
            background: #FFFFFF;
            margin-bottom: 15px;
            // height: 90px;
            width: 580px;

            img {
              width: 120px;
              height: 90px;
            }

            .content {
              padding: 14px 0;
              padding-right: 10px;

              p {
                color: #222222;
                line-height: 29px;

                &:first-child {
                  font-weight: 600;
                  margin-bottom: 4px;
                }


              }
            }

          }

        }
      }
    }
  }
}

@media screen and (min-width:1180px) {
  .scheme .flowPath .WebMain .DivContent .item .left {
    margin-right: 10px;
  }
}
</style>